import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50px"
      height="50px"
      fill="currentcolor"
    >
      <g id="surface34034907">
        <path d="M 25 2 C 12.316406 2 2 12.316406 2 25 C 2 37.683594 12.316406 48 25 48 C 37.683594 48 48 37.683594 48 25 C 48 12.316406 37.683594 2 25 2 Z M 18 35 L 14 35 L 14 20 L 18 20 Z M 16 17 C 14.894531 17 14 16.105469 14 15 C 14 13.894531 14.894531 13 16 13 C 17.105469 13 18 13.894531 18 15 C 18 16.105469 17.105469 17 16 17 Z M 37 35 L 33 35 L 33 27.5 C 33 25.574219 31.425781 24 29.5 24 C 27.574219 24 26 25.574219 26 27.5 L 26 35 L 22 35 L 22 20 L 26 20 L 26 21.816406 C 27.167969 20.695312 28.753906 20 30.5 20 C 34.089844 20 37 22.910156 37 26.5 Z M 37 35 " />
      </g>
    </svg>
  );
}

export default Linkedin;
